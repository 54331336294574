import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import { ToastAction } from '../../../redux/Actions/ToastAction';

const Notification = () => {

    let toast = useSelector(state => state?.Toastify);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(ToastAction({ hide: true }))
    };

    return (
        <Snackbar open={toast?.open} autoHideDuration={5000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={toast?.severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {toast?.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification
