import React, { useEffect, useState } from 'react';
import { Modal, Button, TextField, Box, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "430px",
    width:"90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    padding: "25px",
    "& h2": {
        marginTop: "0",
        fontSize: "24px",
        marginBottom: "26px",
    },
};

const SourceModal = ({ open, toggle, handleSave, content }) => {
    const [sourceContent, setSourceContent] = useState('');

    useEffect(() => {
        setSourceContent(content);
    }, [content, open])

    const handleChange = (event) => {
        setSourceContent(event.target.value);
    };

    const handleSaveClick = () => {
        handleSave(sourceContent);
    };

    return (
        <Modal
            open={open}
            onClose={toggle}
        >
            <Box
                sx={style}
            >
                <Box>
                    <IconButton onClick={toggle} sx={{
                            padding: "0",
                            position: "absolute",
                            right: "15px",
                            top: "15px",
                    }}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <h2 id="source-modal-title">HTML Source</h2>
                <TextField
                    id="source-content"
                    label="Source Content"
                    multiline
                    rows={10}
                    variant="outlined"
                    fullWidth
                    value={sourceContent}
                    onChange={handleChange}
                />
                <Box mt={2} sx={{ textAlign: 'right' }}>
                    <Button onClick={handleSaveClick} variant="contained" color="primary" sx={{
                        background: "#1b8473",
                        boxShadow: "none",
                    }}>
                        Save
                    </Button>        
                </Box>
            </Box>
        </Modal>
    );
};

export default SourceModal;
