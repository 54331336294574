import * as React from 'react';
import { Backdrop, Box, Modal, Fade, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Descope } from '@descope/react-sdk';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "430px",
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const SignIn = ({ isSignInOpen, setIsSignInOpen }) => {

  const handleClose = () => setIsSignInOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isSignInOpen}
      // onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isSignInOpen}>
        <Box sx={style}>
          <Box sx={{ position: 'absolute', top: "5px", right: "5px", zIndex: "999", display: 'inline-flex' }}>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Descope
            flowId="sign-in"
            theme="light"
            onSuccess={(e) => {
              // console.log(e);
              // console.log(e.detail.user.name)
              // console.log(e.detail.user.email)
              setIsSignInOpen(false)
            }}
            onError={(err) => {
              console.log("Error!", err)
            }}
          // redirectUrl='https://ai2humanize.com/'
          />
        </Box>
      </Fade>
    </Modal>
  )
}

export default SignIn
